.success-page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: white;
  img {
    width: 400px;
    object-fit: contain;
  }
  .payment-success{
    font-size: 50px;
    text-align: center;
    color: rgb(2, 156, 2);
  }
  a {
    color: #6c63ff;
    text-decoration: underline;
  }
}

@media only screen and (max-width: 500px) {
  .success-page-container {
    img {
      width: 97%;
    }
    h1 {
      font-size: 30px;
    }
  }
}
