.ant-menu-submenu-title {
    height: auto !important;
}

#nprogress .bar {
    background: #e48900 !important;
}

.input-error {
    border: none;
    color: #F20000;
}

.ant-select-dropdown {
    min-width: 60px !important;
}

.ant-select-selector {
    border: none !important;
    margin: 0 !important;
    padding: 0 !important;
    text-align: center;
}

.ant-select-selection-search {
    margin: 0 !important;
    padding: 0 !important;
    left: auto !important;
    right: auto !important;
}

.ant-select-selection-search-input {
    text-align: center;
}

.ant-select-item {
    padding: 0px 0px !important;
    font-size: 10px;
    text-align: center;
}
.ant-form-item {
    margin-bottom: 6px;
}
.ant-upload-select {
    width: 100%;
}
.input-error::-webkit-calendar-picker-indicator {
    display: none;
}

.transcript-text {
    font-size: 12px;
}

.transcript-segment {
    display: flex;
    flex-wrap: wrap;
}

.transcript-segment > div {
    display: inline;
    margin-top: 2px;
}

.word-label {
    margin-left: 2px;
    margin-right: 2px;
    padding-top: 2px;
}

.word-label-playing {
    color: #4f5df7;
}

.btn-save {
    margin-right: 20px;
}

.ant-table .ant-table-cell {
    line-height: unset !important;
}

td.ant-table-column-sort {
    background: transparent;
}

.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
}

.ant-tag:hover {
    opacity: 1;
}

.verifinput {
    width: 100% !important;
    display: inline-flex !important;
    justify-content: center !important;
}

.styles_react-code-input__CRulA > input {
    border: none !important;
    margin-left: 5px !important;
    margin-right: 5px !important;
    border-bottom: 2px solid #C3C6DB !important;
    border-radius: 0 !important;
}

.slider {
    height: 55vh !important;
}
.carousel .slider-wrapper {
    overflow-y: scroll !important;
}
.slider .previousButton, .slider .nextButton {
    display: none !important;
}

.ant-tooltip-placement-bottom {
    padding-top: 0;
}

.ant-tooltip-inner {
    background-color: transparent;
    box-shadow: none;
    color: #4A60F4;
    font-size: 12px;
    font-family: Lato;
    font-style: normal;
    margin-top: -6px;
}

.ant-tooltip-arrow {
    display: none;
}

::-moz-selection {
    background: #FFA900;
}

::-webkit-selection {
    background: #FFA900;
}

::selection {
    background: #FFA900;
}

.topic-input {
    border: none;
    border-bottom: 1px dashed;
    line-height: 1 !important;
    min-height: 20px !important;
    color: #575757;
}


/* Draggable list*/

.intro,
footer {
    width: 400px;
    margin: auto;
}

.list {
    width: 430px;
    margin: 10px auto;
    padding: 5px;
}
.item {
    overflow: hidden;
    transform-origin: 10% 10% 0px;
    padding-left: 20px;
    background: white;
    max-height: 100%;
    text-align: center;
}
.item .dragHandle {
    visibility: visible;
    position: absolute;
    top: 3px;
    left: 0;
    cursor: move;
    width: 20px;
    height: 16px;
    color: #575757;
    background: url('assets/sort.svg') 30% 50% no-repeat no-repeat;
}
.item:hover .dragHandle,
.item.dragged .dragHandle {
    visibility: visible;
}
.item h2 {
    margin: 0;
}
.item .subtitle {
    font-weight: bold;
}

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}
::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

/**/


@media (max-width: 768px) {
    .ant-menu-submenu .ant-menu-submenu-title {
        display: flex;
        align-items: center;
    }

    .ant-menu-submenu .ant-menu-submenu-title img {
        padding-right: 10px;
    }
}
