.success-page-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  img {
    width: 400px;
    object-fit: contain;
  }
  .payment-failure{
    font-size: 50px;
    text-align: center;
    color: rgb(217, 75, 19);
  }
  a {
    color: #6c63ff;
    text-decoration: underline;

  }
}

@media only screen and (max-width: 500px) {
  .success-page-container {
    img {
      width: 97%;
    }
    h1 {
      font-size: 30px;
    }
  }
}
