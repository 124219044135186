.success-page-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.success-page-container img {
  width: 400px;
  -o-object-fit: contain;
     object-fit: contain;
}
.success-page-container .payment-success {
  font-size: 50px;
  text-align: center;
  color: rgb(2, 156, 2);
}
.success-page-container a {
  color: #6c63ff;
  text-decoration: underline;
}

@media only screen and (max-width: 500px) {
  .success-page-container img {
    width: 97%;
  }
  .success-page-container h1 {
    font-size: 30px;
  }
}/*# sourceMappingURL=SuccessPage.css.map */